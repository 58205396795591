import React from 'react'
import Header from '../components/Layout/Header'
import TableNo from '../components/Items/TableNo'
import FilterData from '../components/Items/FilterData'
import FoodCard from '../components/Items/FoodCard'
const Reacttest = () => {
  return (
    <div>
      <Header />
      <TableNo />
      <FilterData />
      <FoodCard />
    </div>
  )
}

export default Reacttest
