import React from 'react'

const Header = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between border-bottom">
        <a className="navbar-brand mb-0 h1">KMAKAM</a>
        <div className='my-2 my-lg-0'>

        <button className="btn btn-primary">Orders <span className='badge badge-light'>0</span></button>
        </div>
      </nav>
    </div>
  )
}

export default Header
