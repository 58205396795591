import React from "react";
import Slider from "react-slick";
import Testimonial from "../Items/Testimonial";

const testimonialsData = [
  {
    id: 1,
    content:
      "I have a pleasure to work with Kiran on a major FTCI program including Agile transformation. He is a best and most socially mature member of the team. He is always delivering high grade and high-quality product. He is always on front of the team and always having more load than everybody else. His great character and willingness to extend helping hand, share knowledge or just be a great teammate certainly provide great contribution to a team success and quality of team life. Kiran always strive to learn and implement new technologies. As PM I wish I have more people and friens like Kiran.",
    authorImage: "https://media.licdn.com/dms/image/C4E03AQEwRsrFfqrIBQ/profile-displayphoto-shrink_100_100/0/1583848440575?e=1694649600&v=beta&t=nYkRZBik9q_S5-8n4bRJofw3RNaIUg1XhLDl7aqseUw",
    authorName: "Alex gas",
    authorStatus: "Agile Coach",
  },
  {
    id: 2,
    content:
      "I collaborated with Kiran for almost 2 years to build, launch and iterate on a customer web portal. He was easy to work with, had great communication and offered good solutions whenever there were complications implementing my designs. He was a true partner and I valued his input. His work is top notch.",
    authorImage: "https://media.licdn.com/dms/image/D5603AQFz-zGxCGchEA/profile-displayphoto-shrink_100_100/0/1685508267778?e=1694649600&v=beta&t=rBUOYeqgdsvXajlL8TrAZj8Rmg3CzKjBS25_79CmNdg",
    authorName: "Brian Dainiels",
    authorStatus: "Product Designer",
  },
  {
    id: 3,
    content:
      "While working with Kiran in one of the projects and building the design of the application, I was very impressed on how quickly he can take feedbacks and implement those feedbacks onto the design and come up with solutions. It’s always a pleasure to work with him.",
    authorImage: "https://media.licdn.com/dms/image/D4E03AQFGnr1XOEHwog/profile-displayphoto-shrink_100_100/0/1670651684381?e=1694649600&v=beta&t=xACSFXHgOFZNfNsojfyDeR8OlhIP25369UZeHmqAccU",
    authorName: "Forhad Ahmad",
    authorStatus: "Product and People Leader",
  },
  {
    id: 3,
    content:
      "Kiran is a very creative person. The ideas he has coupled with the knowledge and skills, made him a very important part of our project. Most importantly, he is very quick to understand client requirements and delivers the project in a timely manner",
    authorImage: "https://media.licdn.com/dms/image/C5603AQEjFVrnioWZSw/profile-displayphoto-shrink_100_100/0/1613716053797?e=1694649600&v=beta&t=X4zoDbhRvL0SgWT2pMLZooWuSDZ3jIBYwHkV99vvG7M",
    authorName: "Dhruv Patel",
    authorStatus: "Dirrrector, RK Projects",
  },
];

function  Testimonials() {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  return (
    <div className="testimonials-wrapper">
      <Slider {...settings} className="padding-slider">
        {testimonialsData.map((testimonial) => (
          <Testimonial testimonial={testimonial} key={testimonial.id} />
        ))}
      </Slider>
    </div>
  );
}

export default Testimonials;
