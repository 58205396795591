import react from 'react';

const TableNo = () => {
    const Numbers = [1,2,3,4,5,6,7,8,9,10];
    const InActive = "btn btn-secondary";
    const Active = "btn btn-secondary active"

    const [table,setTableNumber] = react.useState(null);

  return (
    <div className='container d-flex align-items-center my-4 flex-column'>
        <h4>Please Select Table Number:</h4>
      <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            <div className="btn-group mr-2" role="group" aria-label="First group">
                {Numbers.map((num,index) => (
                    <button type="button" className={table === num?Active:InActive} onClick={() => setTableNumber(num)} key={index} >{num}</button>
                ))}
            </div>
        </div>
    </div>
  )
}

export default TableNo
