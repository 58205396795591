import React from "react";
import Experience from "../Items/Experience";

const experiencesData = [
  {
    id: 1,
    year: "Dec 2021 - Present",
    logoimg: "https://media.licdn.com/dms/image/C4D0BAQF-RIoeeMTMKQ/company-logo_100_100/0/1664197007563?e=1697068800&v=beta&t=H3QYIOhQGfm60_ShftVUEOEA1oaSgB0VEhwWTJO9YFk",
    degree: "UX Design Manager",
    content:
      "Worked closely with cross-functional teams, including product managers, developers, and marketing specialists to identify and implement new solutions for product direction, image, and user experience. Led a team of designers in creating and testing design concepts through sketching, prototyping, and usability testing, resulting in improved user experience and increased user satisfaction. Collaborate closely with product managers to define product roadmaps and prioritize based on user needs and business objectives. Present design work to stakeholders and executive leadership, effectively communicate design ideas and facilitate the decision-making process. Support design processes, principles, and tools within the organization, driving collaboration and problem solving among peers. Contribute to the development and maintenance of design systems, ensuring consistency in visual elements, styles, and interactions across products. Conduct user research and usability testing to gather insights and verify design solutions, making sure they align with user needs and expectations. Contribute to the development of UX/UI design systems and guidelines, facilitating a consistent and consistent user experience across all company products. Collaborate with front-end developers to ensure the correct translation of design into a functional and responsive interface, offering guidance and support in HTML, CSS, and JavaScript as needed.",
  },
  {
    id: 2,
    year: "Mar 2021 - Dec 2021",
    logoimg: "https://media.licdn.com/dms/image/C560BAQHp1kpjCizStA/company-logo_100_100/0/1650474046796?e=1697068800&v=beta&t=TpyBnfKTgBksEQyvj6VutNjdp21BylbZu7YX3E3jEUk",
    degree: "enior UX Designer",
    content:
      "Designed intuitive and user-centric interfaces for software products, following best practices in user experience design. Conducted user research, including interviews and usability testing, to gain insights and validate design decisions. Collaborated with cross-functional teams, including engineers and product managers, to implement design solutions that met technical and business requirements. Contributed to improving the design process by introducing new tools and methodologies that resulted in increased efficiency and design quality. Implemented SASS functions and mixins.      ",
  },
  {
    id: 3,
    year: "Dec 2018 - Feb 2021",
    logoimg: "https://media.licdn.com/dms/image/D560BAQFg8It0OZGoBQ/company-logo_100_100/0/1688158406775?e=1697068800&v=beta&t=R2tHPwuxwpqRg3PljViBsHomFLHd_mn8u1gzFAPmx1c",
    degree: "UX UI Lead Developer",
    content:
      "Participate in user interface design UI/UX process, UI requirements, converting findings into UI designs. Analyze the requirements of the clients and prepare possible analyzes of chart formats which can be adapted to the client's business. Developed enterprise level applications and platform in corner and follow best practices Interacting with Customers on various status updates and analysis of requirements. Collaborate with team & cross team collaboration to develop features, and UX for FTCI customers Scrum management, story creations, assignment of work, backend management, version validations. Code reviews performed. - Provided technical guidance to the team and acted as module guide. Provide statistics on assigned tasks Experience of creating modern UI as a SPA application using angular 8, html, css typescript and Javascript. Experience in pixel-level CSS, Stability with browsers, Feature and Platform Independence. Work with CSS3 and Flex Material to create responsive web designs for multiple categories/pages. A dynamic MVC framework to organize Angular 7 application controllers, Custom directives, factories and views. Used Git for version control and JIRA for defect tracking. Pages created for login using single sign on, main page containing preferences and page for continuous integration configuration. Create animation effects during data load, waiting for Ajax response using CSS animations. Interact with other developers and end users to design and implement new features throughout the process. Debugging using firebug and web development tools on Chrome, Firefox and Internet Explorer. Work with business analysts to understand their business models and goals. Web pages developed using HTML/CSS and JavaScript      ",
  },
];

function Experiences() {
  return (
    <div className="timeline">
      {experiencesData.map((experience) => (
        <Experience experience={experience} key={experience.id} />
      ))}
      <span className="timeline-line"></span>
    </div>
  );
}

export default Experiences;
