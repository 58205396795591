import React from 'react'

const FoodCard = () => {
    const [data, setData] = React.useState([]);
    React.useEffect(()=>{
        fetch('https://food-itema-default-rtdb.firebaseio.com/telugu-skillhub-api/-MsE8GfWtRjc8x_t8pCC.json')
        .then((response) => response.json())
        .then((data) => setData(data.items));
    },[])
    
  return (
    <div>
        {data.length > 0 ?
        <div className="container">
            <div className="row">
                {data.map((item) => (
                    <div className="col-md-4 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <img src={item.url} alt="" srcset="" />
                                <p className="card-title">{item.title}</p>
                            </div>
                            <div className="card-footer d-flex justify-content-between align-items-center">
                                <h4>Rs.{item.prize}/-</h4>
                                <button className="btn btn-sm btn-primary">Order</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        : <div className="spinner bg-danger rounded"></div>
        }
    </div>
  )
}

export default FoodCard
