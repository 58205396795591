import React, { useEffect } from "react";
import Layout2 from "../components/Layout/Layout2";
import SectionHeading from "../components/Items/SectionHeading";

function Works() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout2>
      <section className="shadow-blue white-bg padding mt-0">
        <SectionHeading title="My Portfolio is Under Construction" />
        <p>Hi there! My portfolio is currently under construction, but I will be adding new projects and content soon.</p>
  <p>In the meantime, you can check out my portfolio PDF here.</p>
        <a
            href="https://w3k.in/download/UI_UX_designer_portfolio-kiran_babu_makam.pdf"
            className="btn btn-default"
          >
            <i className="fas fa-circle-notch"></i> Download Portfolio PDF
          </a>
      </section>
    </Layout2>
  );
}

export default Works;
