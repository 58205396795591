import React from "react";

function Experience({ experience: { year, degree, content, logoimg } }) {
  return (
    <div className="entry">
      <div className="title">
        <p>{year}</p>
        <img src={logoimg} width="80" alt="Comapny Logo"/>
      </div>
      <div className="body">
        <h4 className="mt-0">{degree}</h4>
        <p>{content}</p>
      </div>
    </div>
  );
}

export default Experience;
