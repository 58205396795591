import React from "react";
import TrackVisibility from "react-on-screen";
import Skill from "../Items/Skill";

const skillData = {
  skillContent:
    "",
  progressData: [
    {
      id: 1,
      name: "Figma",
      percentage: 95,
    },
    {
      id: 2,
      name: "XD",
      percentage: 90,
    },
    {
      id: 3,
      name: "Sketch",
      percentage: 60,
    },
    {
      id: 4,
      name: "Photoshop",
      percentage: 90,
    },
    {
      id: 5,
      name: "Illustrator",
      percentage: 90,
    },
    {
      id: 6,
      name: "HTML / CSS",
      percentage: 90,
    },
    {
      id: 7,
      name: "React / Angular",
      percentage: 50,
    },
    {
      id: 8,
      name: "SCSS",
      percentage: 80,
    },
    {
      id: 9,
      name: "Bootstrap / Matirial",
      percentage: 80,
    },
    {
      id: 10,
      name: "Agile",
      percentage: 60,
    },
    {
      id: 11,
      name: "People Management",
      percentage: 60,
    },
  ],
};

function Skills() {
  return (
    <>
      <p className="mb-0">{skillData.skillContent}</p>
      <div className="mt-5">
        <div className="row -mt-50">
          {skillData.progressData.map((progress) => (
            <div className="col-md-4 mt-50" key={progress.id}>
              <TrackVisibility once>
                <Skill progress={progress} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Skills;
